<template>
  <div class="flag" @click="selectCountry">
    <div class="flag-image">
      <!-- 使用国旗 emoji -->
      <span role="img" aria-label="Country flag">{{ country.flag }}</span>
    </div>
    <div class="flag-name">{{ country.name }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Country } from "@/config/types";

export default defineComponent({
  name: "CountryFlag",
  props: {
    country: {
      type: Object as () => Country,
      required: true,
    },
  },
  emits: ["select"],
  methods: {
    selectCountry() {
      this.$emit("select", this.country.url);
    },
  },
});
</script>

<style scoped>
.flag {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  margin: 10px;
  width: 100px; /* 设置一个合适的宽度 */
  height: 100px; /* 设置一个合适的高度 */
  border: 1px solid #ccc; /* 添加边框 */
  border-radius: 4px; /* 圆角边框 */
  cursor: pointer;
  transition: transform 0.3s ease; /* 平滑变换效果 */
  overflow: hidden; /* 防止内容溢出 */
  text-align: center; /* 文本居中 */
}

.flag:hover {
  transform: scale(1.1); /* 鼠标悬停时放大 */
}

.flag-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  height: 100%; /* 占满整个 flag 的高度 */
}

.flag-image {
  font-size: 2em; /* 国旗emoji的大小 */
  margin-bottom: 5px; /* 与国家名称之间的间距 */
}

.flag-name {
  font-size: 0.8em; /* 国家名称的字体大小 */
  white-space: nowrap; /* 防止换行 */
}
</style>
