import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Leader from "../views/Leader.vue";
import Traveler from "../views/Traveler.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/leader",
    name: "Leader",
    component: Leader,
  },
  {
    path: "/traveler",
    name: "Traveler",
    component: Traveler,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
