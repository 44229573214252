<template>
  <div class="home">
    <div
      class="half leader"
      :class="{ 'is-clicked': isLeaderClicked }"
      @click="handleClick('/leader')"
    >
      <div class="entry">
        <i class="fa-solid fa-people-group icon"></i>
        <span>领队入口</span>
      </div>
    </div>
    <div
      class="half traveler"
      :class="{ 'is-clicked': isTravelerClicked }"
      @click="handleClick('/traveler')"
    >
      <div class="entry">
        <i class="fa-solid fa-person icon"></i>
        <span>旅客入口</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  setup() {
    const router = useRouter();
    const isLeaderClicked = ref(false);
    const isTravelerClicked = ref(false);

    const handleClick = (path: string) => {
      // 根据点击的路径设置相应的状态为 true
      if (path === "/leader") {
        isLeaderClicked.value = true;
      } else {
        isTravelerClicked.value = true;
      }

      // 延迟跳转，确保动画完成后再跳转
      setTimeout(() => {
        router.push(path);
      }, 100);
    };

    return { handleClick, isLeaderClicked, isTravelerClicked };
  },
});
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.half {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: transform 0.5s ease-out; /* 定义过渡效果 */
}

.leader {
  background-color: #42b983;
}

.traveler {
  background-color: #35495e;
}

.entry {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.icon {
  margin-right: 20px;
  font-size: 2em;
}

.entry span {
  font-size: 2em;
  font-weight: bold;
}

/* 定义点击后的放大效果 */
.is-clicked {
  transform: scale(1.1); /* 点击后放大到 110% */
}

.half:hover {
  opacity: 1;
}
</style>
