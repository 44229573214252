<template>
  <div class="traveler">
    <h1>功能建设中<br />敬请期待</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Traveler",
});
</script>

<style scoped>
.traveler {
  text-align: center;
  padding: 50px;
}
</style>
