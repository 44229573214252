<template>
  <div class="leader">
    <h1>请选择要入境的国家</h1>
    <div class="flags">
      <CountryFlag
        v-for="country in countries"
        :key="country.code"
        :country="country"
        @select="goToCountry"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Country } from "@/config/types";
import CountryFlag from "@/components/CountryFlag.vue";
import countriesData from "@/config/countries.json";

type Countries = Country[];

export default defineComponent({
  name: "Leader",
  components: {
    CountryFlag,
  },
  setup() {
    const countries: Countries = countriesData;
    const goToCountry = (url: string) => {
      location.href = url;
    };
    return { countries, goToCountry };
  },
});
</script>

<style scoped>
.leader {
  text-align: center;
}
.flags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
